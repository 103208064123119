import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <p>"Meet Upon the Level and Part Upon the Square"</p>
        </div>
        <div className="card-body">
          <p>
            Greetings Brethren, as we embark on the month of August, it is with
            great pleasure that we bring you the latest updates and news from
            our beloved Masonic community. In the spirit of brotherhood and
            unity, we hope this newsletter finds you well and thriving in both
            your personal and Masonic endeavors.
          </p>
          <p>
            Our July Carl E. Pippin Sr Memorial Fish Fry Fundraiser was a
            resounding success! Advertisement was presented with Worshipful
            Scott Hammel and his wife Carrie's Large, home made, Fish Fry Street
            Sign and Brother Bora Yildirim and his daughters roadside enthusiasm
            to guide and direct passerby's to our Lodge. The rainfall made the
            day cooler, but may have slowed some of the purchases during the
            heaviest downpours. Overall we had a great and enjoyable turnout.
          </p>
          <p>
            Let us welcome our newest Masters Masons- Kristopher Wood Meyer, and
            Ahmed Moustafa Elzawawy and let us welcome our newest E.A., Sean
            Michael Munson, who has recently joined our fraternity, in July. We
            look forward to getting to know you better and sharing in the
            journey of Masonry together.
          </p>
          <p>
            Our presentation this month will be from The Widow's Sons. This is a
            new chapter in our district. Worshipful McPeak will be presenting.
          </p>
          <p>
            We will be having a Blood drive event on August 22, from 1pm-6pm.
            Worshipful Ford Pugh will be in charge. Spaghetti will be provided.
            The event will be held at our Prince George Lodge. So come out, and
            help save a life!
          </p>
          <p>
            Great news our Lodge Riding mower has been repaired. I have taken it
            out and cut the grass around the Lodge. Unfortunately with all this
            rain it will need to be cut often, so repairs have come in the nick
            of time.
          </p>
          <p>
            As always, the strength of our fraternity lies in the active
            participation and engagement of its members. Let us continue to
            uphold the principles of Freemasonry and work together to make our
            lodge a beacon of light and wisdom.
          </p>
          <p>Wishing you a fulfilling and prosperous August.</p>
        </div>
        <div className="card-footer">
          <p>William "Bill" Coyner</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
